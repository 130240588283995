import {
    createContext,
    useContext,
    useEffect,
    useState,
    PropsWithChildren,
} from "react";
import { useUpdateUserMutation } from "hooks/useUser";
import { useAuth } from "state/auth";
import { noFrameSendMessage } from "./NoFrameSendMessage";
import { useSessionStorage } from "../../hooks/useSessionStorage";

const CHROME_STORE_EXTENSION_ID = "nklgajbllblebcjflehaifaooeiakgnm";
const OVERRIDE_STORAGE_KEY = "noframing-extension-id";

export type NoFrameExtensionContextType = {
    connected: boolean;
    extensionId: string;
    port: chrome.runtime.Port | undefined;
};

const NoFrameExtensionContext = createContext<NoFrameExtensionContextType>(
    null!!!
);

export const useNoFrameExtension = () => {
    return useContext(NoFrameExtensionContext);
};

export const NoFrameExtensionProvider = (props: PropsWithChildren<{}>) => {
    const [connected, setConnected] = useState(false);
    const [port, setPort] = useState<chrome.runtime.Port | undefined>();
    const { loggedIn } = useAuth();
    const { mutate: updateUser } = useUpdateUserMutation();
    const overrideExtensionId = useSessionStorage(OVERRIDE_STORAGE_KEY);

    const extensionId = overrideExtensionId || CHROME_STORE_EXTENSION_ID;

    console.log("using extension id", extensionId);

    useEffect(() => {
        let port: chrome.runtime.Port | undefined;
        async function attemptConnection() {
            if (!loggedIn) {
                // not logged in yet, don't try to connect
                return;
            }

            if (extensionId) {
                try {
                    port = chrome.runtime.connect(extensionId);
                    const { connection_success } = await noFrameSendMessage(
                        extensionId,
                        "connect-to-extension",
                        { tbx_payload: {} }
                    );
                    if (connection_success) {
                        console.log("connected to extension", extensionId);
                        updateUser({
                            noframing_extension_last_connected_at:
                                new Date().toISOString(),
                        });
                        setPort(port);
                        setConnected(true);
                    } else {
                        throw new Error("noFrame extension not found!");
                    }
                } catch (e) {
                    console.error("failed to connect to noFrame extension", e);
                    setPort(undefined);
                    setConnected(false);
                }
            }
        }
        attemptConnection();
        return () => {
            if (port) {
                port.disconnect();
                console.log("disconnected from extension", extensionId);
            }
        };
    }, [extensionId, updateUser, loggedIn]);

    return (
        <NoFrameExtensionContext.Provider
            value={{ connected, extensionId, port }}
        >
            {props.children}
        </NoFrameExtensionContext.Provider>
    );
};
